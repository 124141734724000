import React from "react";
// import { Link } from "gatsby";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Pitch = ({ pitch }) => (
  <div className="has-text-centered box has-background-black">
    <h2 className="title has-text-white is-spaced">{pitch.title}</h2>
    <h3 className="subtitle is-size-4 has-text-white">{pitch.text}</h3>
    <br />
    {/* <Link
      className="button is-rounded is-medium is-white has-text-weight-semibold"
      to="/why"
    >
      <span className="fa-fw fa-2x">
        <FontAwesomeIcon
          icon="arrow-alt-circle-right"
          color="#000"
          transform="shrink-6"
        />
      </span>
      <span className="has-text-weight-semibold">{pitch.cta}</span>
    </Link> */}
  </div>
);

export default Pitch;
